import React, { useLayoutEffect, useRef } from 'react';
import '../../../style/components/modal/Modal.less';
import { hideModal, showModal } from '../../lib/modal.jsx';
import { mergeClassNames } from '../../lib/dom.jsx';

/**
 * 这是一个弹窗的底层组件，用于显示和隐藏弹窗内容。
 * 1. 不支持单独使用。
 * 2. 必须传入isOpen属性来控制弹窗的显示和隐藏。
 * 3. 必须传入onClose属性来处理关闭事件（设置isOpen为false)。
 *
 */
export function Modal({ isOpen, children, className, id }) {
    const modalRef = useRef();
    useLayoutEffect(() => {
        if (isOpen) {
            showModal(modalRef.current);
        } else {
            hideModal(modalRef.current);
        }
    });
    return (
        <div
            id={id}
            className={mergeClassNames(className, 'modal')}
            ref={modalRef}
            style={{ display: 'none' }}
        >
            <div className="backdrop"></div>
            {children}
        </div>
    );
}
