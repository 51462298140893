import { isArray } from 'lodash';

export function mergeClassNames() {
    const classNameList = arguments;
    const classNames = [];
    for (let i = 0; i < classNameList.length; i++) {
        const className = classNameList[i];
        if (typeof className === 'string') {
            classNames.push(...className.split(' '));
        } else if (isArray(className)) {
            classNames.push(...className);
        } else if (typeof className === 'object') {
            for (let key in className) {
                if (className[key]) {
                    classNames.push(key);
                }
            }
        }
    }
    return classNames.join(' ');
}

export function isDisabled(v) {
    let value = v;
    if (typeof value === 'string') {
        value = value.toLowerCase();
    }
    return ['disabled', 'true', '1', 1, true].includes(v);
}
