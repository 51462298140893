import { sleep } from "./common.jsx";

const DEFAULT_TRANSITION_DURATION = 300; // ms

function createTransitionClassName(transitionName, inOrOut, startOrEnd) {
    return `${transitionName}-${inOrOut}-${startOrEnd}`;
}

/**
 * 进场动画
 * @param {HTMLElement} element
 */
async function transitionIn(element, transitionName) {
    const startClass = createTransitionClassName(transitionName, "in", "start");
    const endClass = createTransitionClassName(transitionName, "in", "end");
    element.classList.add(startClass);
    await sleep(1);
    element.classList.add(endClass);
    await sleep(DEFAULT_TRANSITION_DURATION);
    element.classList.remove(startClass);
    element.classList.remove(endClass);
}

/**
 * 退场动画
 * @param {HTMLElement} element
 */
async function transitionOut(element, transitionName) {
    const startClass = createTransitionClassName(
        transitionName,
        "out",
        "start"
    );
    const endClass = createTransitionClassName(transitionName, "out", "end");
    element.classList.add(startClass);
    await sleep(1);
    element.classList.add(endClass);
    await sleep(DEFAULT_TRANSITION_DURATION);
    element.classList.remove(startClass);
    element.classList.remove(endClass);
}

/**
 *  显示弹窗
 * @param {HTMLDivElement} modalEl
 */
export async function showModal(modalEl) {
    modalEl.style.display = "flex";
    await transitionIn(modalEl, "fade");
}
/**
 *  隐藏弹窗
 * @param {HTMLDivElement} modalEl
 */
export async function hideModal(modalEl) {
    await transitionOut(modalEl, "fade");
    modalEl.style.display = "none";
}
/**
 * 切换弹窗的显示状态
 * @param {HTMLDivElement} modalEl
 */
export async function toggleModal(modalEl) {
    if (modalEl.classList.contains("hidden")) {
        await showModal(modalEl);
    } else {
        await hideModal(modalEl);
    }
}
